body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
    height: 100%;
}

header {
    height: 9%;
}

.innerHeader {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 60%;
}

main {
    height: 91%
}

.centerFlexBox {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
}

.hoverCell:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

html {
    font-size: 16px;
}

@media (max-width: 1600px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 1300px) {
    html {
        font-size: 12px;
    }
}
