.menuItemIcon  {
    margin-right: 0.4em;
    font-size: 16px;
}

.modusSelectItem{
    display : inline;
    padding-bottom: 5px !important;
    margin-right: 10px;
}

.modusSelectItem:hover{
    color : orange;
    cursor: pointer;
}

.modusSelectItemSelected
{
    border-bottom: 2px solid darkorange  !important;
    color: darkorange !important;
}

.horiz-menu > div {
    display: inline-block;
}