.panelContainer {
    display: flex;
    height: 100%;
    width: 100%;
}

.panel {
    border: 1px solid gray;
    overflow-y: auto;
    overflow-x: hidden;
}

.resizer {
    width: 2px;
    background: darkgray;
    position: relative;
    cursor: col-resize;
    flex-shrink: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.resizer::after,
.resizer::before {
    content: "";
    border-left: 2px solid #333;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: 0;
    display: inline-block;
    height: 20px;
    margin: 0 2px;
}

.resizer::before {
    left: 0;
}