.performerDetailContainer {
    width: 100%;
}

.performerDetailItem {
    margin: auto;
    width: 85%;
    padding-top: 1.5em;
}


.performerAvatar {
    margin: auto;
}


.avatarContainer {
    position: relative;
}

.avatarContainer:hover .overlay {
    opacity: 0.6;
}

.avatarContainer:hover .editIcon {
    display: block !important;
}

.avatarContainer {
    width: 120px;
    height: 120px;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    mask-image: radial-gradient(circle, white 100%, transparent 100%);
    -webkit-mask-image: radial-gradient(circle, white 100%, transparent 100%);
}

.overlay {
    position: absolute;
    top: 0;
    left: calc(50% - 60px);
    margin-left: auto;
    width: 120px;
    height: 100%;
    background: gray;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;
}

.editIcon {
    position: absolute !important;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none !important;
    z-index: 1;
}